<template>
	<section class="agency-login">
		<div class="container">
			<div class="agency-login__wrapper">
				<Breadcrumb :pages="pages" />

				<div class="agency-login__body">
					<Transition name="slide-broker" mode="out-in">
						<AgentRegForm v-if="!isSubmit" @next="() => (isSubmit = true)" />
						<RegVerification
							class="agency-login__verification"
							v-else
							@prev="() => (isSubmit = false)"
						/>
					</Transition>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue'
import AgentRegForm from '@/modules/broker/components/Registration/AgencyForm.vue'
import RegVerification from '@/modules/broker/components/Registration/RegVerification.vue'

export default {
	name: 'AgentLogin',
	components: {
		RegVerification,
		AgentRegForm,
		Breadcrumb
	},
	data() {
		return {
			isSubmit: false
		}
	},
	computed: {
		pages() {
			return {
				first: {
					title: this.$t('general.home'),
					srcName: 'MainRoute'
				},
				current: this.$t('web.pages_agency_register')
			}
		}
	}
}
</script>

<style lang="scss">
.agency-login {
	margin-bottom: 80px;
	&__wrapper {
		display: flex;
		flex-direction: column;
		gap: 40px;
	}
	&__body {
		width: 100%;
		justify-content: center;
		display: flex;
	}
	&__verification {
		margin-top: 40px;
	}
	@media screen and (max-width: 479px) {
		margin-bottom: 0;
		&__verification {
			margin-top: 20px;
		}
	}
}
</style>
